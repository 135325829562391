import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { EventsService } from '../../services/events.service';
import { EventModel } from '../../models/event.model';
import { LoadingService } from '../../services/loading.service';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss']
})
export class RefreshButtonComponent implements OnInit, OnDestroy {
  public _disabled: boolean;
  public refreshTimer: { label: string, value: number };
  public timeIntervals: Array<{label: string, value: any}>;
  public loading: boolean;
  @Input() eventId: string;
  @Input() set disabled (value: boolean) {
    this._disabled = value;
  }
  @Output() refreshComplete: EventEmitter<any> = new EventEmitter<any>();
  @Output() click: EventEmitter<any> = new EventEmitter<any>();

  constructor(private eventsService: EventsService, public loadingService: LoadingService, private analytics: AnalyticsService) {
    this.disabled = false;
    this.timeIntervals = [{
      label: 'Manual',
      value: 'off'
    }, {
      label: '1m',
      value: 60000
    }, {
      label: '5m',
      value: 300000
    }, {
      label: '30s',
      value: 30000
    }];
    this.refreshTimer = { label: this.timeIntervals[0].label, value: this.timeIntervals[0].value };
    this.loading = false;
  }

  ngOnInit() {
    this.setTime(JSON.parse(localStorage.getItem('timerSettings')));
  }
  ngOnDestroy() {
    this.stopTimer(true);
  }

  onClick(event: any) {
    if (!this._disabled) {
      this.click.emit(event);
      if (this.eventId) {
        this.loadingService.isLoading = true;
        this.loading = true;
        this.eventsService.getEvent(this.eventId, true).pipe(
          finalize(() => {
            this.loadingService.isLoading = false;
            this.loading = false;
          })
        ).subscribe((e: EventModel) => {
          this.refreshComplete.emit(e);
          void this.analytics.track('GlobalRefresh Refreshed');
        });
      }
      this.stopTimer(false);
    }
  }

  setTime(_time) {
    if (_time === null) {
      _time = this.refreshTimer;
    }
    localStorage.setItem('timerSettings', JSON.stringify(_time));
    this.refreshTimer.label = _time.label;
    this.stopTimer(false);
    void this.analytics.track('GlobalRefresh TimerSet', {
      interval: _time.label
    });
  }

  stopTimer(leaving) {
    clearInterval(this.refreshTimer.value);
    const currentTimer = JSON.parse( localStorage.getItem('timerSettings'));
    if (!(currentTimer.value === 'off' || leaving)) {
      this.startTimer(currentTimer);
    }
  }

  startTimer(currentTimer) {
    this.refreshTimer.value = window.setInterval(() => {
      if ((!this.eventsService.blockAutoRefreshTimer) &&  (!this.loadingService.isLoading)) {
        this.onClick(Event);
      }
    }, currentTimer.value);
  }
}
