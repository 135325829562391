import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContextualMenuItem } from '../shared/route-utils.service';
import { OfflineService } from './offline.service';
import { AnalyticsService } from './analytics.service';

@Injectable({ providedIn: 'root' })
export class GlobalHeaderService {
  public menuItems: any[];
  public mobileClasses: string[];
  public showRefreshEvent: boolean;
  public title: string;
  public readonly titleChange: EventEmitter<string>;
  private readonly _menuItemSelected: EventEmitter<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private offlineService: OfflineService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private analytics: AnalyticsService,
  ) {
    this._menuItemSelected = new EventEmitter<any>();
    this.titleChange = new EventEmitter<string>(true);
    this.title = '';
    this.menuItems = [];
    this.mobileClasses = [];
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // this.menuItems = this.findContextualMenu(route.snapshot) || [];
        this.showRefreshEvent = this.findRefreshButtonToggle(route.snapshot) || false;
      }
    });
  }

  public setTitle(value: string): any {
    this.title = value;
    this.titleChange.emit(this.title);
  }

  /**
   * Listens for menu item selection and emits the selected menu item or displays a message if the menu item is disabled.
   * @param menuItem - The selected menu item.
   * @returns {void}
   */
  public onMenuItemSelected(menuItem: ContextualMenuItem): void {
    // When the menu item is disabled, display the message and prevent further functionality.
    if (menuItem.disabled) {
      this.disabledByMessageHelper(menuItem.disabledByMessages);
      return;
    }

    // Emits the selected menu item.
    this._menuItemSelected.emit(menuItem);
    
    void this.analytics.track('GlobalContextMenu ActionSelected', {
      action: menuItem.value
    });
  }

  /**
   * Returns the menu item selection emitter.
   * @returns {EventEmitter<ContextualMenuItem>}
   */
  public getMenuSelectEmitter(): EventEmitter<ContextualMenuItem> {
    return this._menuItemSelected;
  }

  private findContextualMenu (route: ActivatedRouteSnapshot): any[] {
    if ('contextualMenu' in route.data) {
      return route.data.contextualMenu;
    } else if (route.children.length > 0) {
      return this.findContextualMenu(route.children[0]);
    } else {
      return null;
    }
  }

  private findRefreshButtonToggle (route: ActivatedRouteSnapshot): boolean {
    if ('showRefreshEvent' in route.data) {
      return route.data.showRefreshEvent;
    } else if (route.children.length > 0) {
      return this.findRefreshButtonToggle(route.children[0]);
    } else {
      return null;
    }
  }

  private findPageTitle (route: ActivatedRouteSnapshot): string {
    if (route.children.length > 0) {
      return this.findPageTitle(route.children[0]) || route.data.title;
    } else if ('title' in route.data) {
      return route.data.title;
    } else {
      return null;
    }
  }

  /**
   * Helper function to display a message when a menu item is disabled.
   * @param disabledByMessages
   * @returns {void}
   */
  private disabledByMessageHelper(disabledByMessages: string[]): void {
    // Get the first message key from the array of messages (incorrectPrivileges is the default message).
    const translationKey: string = disabledByMessages?.length ? disabledByMessages[0] : 'snackbarMessages.incorrectPrivileges';

    // Translate the message key and display the message.
    this.translate.get(translationKey).subscribe(msg => {
      this.snackBar.open(msg, 'OK');
    });
  }
}
