import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { UsersService } from './users.service';
import { JwtService } from './jwt.service';

declare var cordova;
declare var device;


const segmentAnalyticsBrowserFactory = () => {
  try {
    return AnalyticsBrowser.load({writeKey: environment.external.segmentWriteKey});
  } catch (error) {
    console.warn('Analytics failed to load', error);
  }
};

export const segmentAnalyticsBrowserProvider = {
  provide: AnalyticsBrowser,
  useFactory: segmentAnalyticsBrowserFactory
};

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private defaultProperties: Record<string, unknown> = {};

  constructor(
    private analytics: AnalyticsBrowser,
    private jwtService: JwtService,
    private usersService: UsersService,
  ) {
    this.defaultProperties = this.getDefaultProperties();
  }

  @IfAtFirstYouDontSucceedGiveUpAndLog()
  async identify(): Promise<void>  {
    const user = await this.usersService.getMe().toPromise();
    this.defaultProperties = this.getDefaultProperties();

    await this.analytics.identify(user.id);
  }

  @IfAtFirstYouDontSucceedGiveUpAndLog()
  async page(name?: string, category?: string, properties?: Record<string, unknown>): Promise<void> {
    await this.analytics.page(category, name, {
      ...this.defaultProperties,
      ...properties
    });
  }

  @IfAtFirstYouDontSucceedGiveUpAndLog()
  async track(eventName: string, properties?: Record<string, unknown>): Promise<void> {
    await this.analytics.track(eventName, {
      ...this.defaultProperties,
      ...properties
    });
  }

  @IfAtFirstYouDontSucceedGiveUpAndLog()
  async reset(): Promise<void> {
    await this.analytics.reset();
    this.defaultProperties = this.getDefaultProperties();
  }

  private getDefaultProperties(): Record<string, unknown> {
    const isMobile = typeof cordova !== 'undefined';
    const platform = typeof device !== 'undefined' ? device.platform : 'browser';

    return {
      app: 'Assessor',
      env: environment.env,
      organisation: this.maybeGetOrg(),
      isMobile,
      platform,
    };
  }

  private maybeGetOrg(): string | undefined {
    try {
      return this.jwtService.getContextOrg().name;
    } catch (e) {
      // unavailable (i.e. not logged in)
      return undefined;
    }
  }
}


function IfAtFirstYouDontSucceedGiveUpAndLog<T extends (...args: any[]) => any>() {
  return function (_target: unknown, propertyKey: string, descriptor: TypedPropertyDescriptor<T>) {
    const originalMethod = descriptor.value;

    descriptor.value = function(...args: Parameters<T>): ReturnType<T> {
      try {
        const result = originalMethod.apply(this, args);
        if (result instanceof Promise) {
          return result.catch((error) => {
            console.warn(`${AnalyticsService.name}.${propertyKey}`, error);
          }) as ReturnType<T>;
        }
        return result;
      } catch (error) {
        console.warn(`${AnalyticsService.name}.${propertyKey}`, error);
      }
    } as T;
  };
}
